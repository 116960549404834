<template>
    <div class="fill-height mt-5 ml-3">
        <div>
            <span class="title">Registros de cambios de estado en espacios</span>
        </div>
        <div class="mb-5">
            <span class="subtitle-2">Selecciona el rango de fechas en el que deseas visualizar la información. La información se visualiza en la tabla de la parte inferior</span>
        </div>

        <!-- TABLA y Textfield de busqueda -->   
        <div>
            <v-container fluid fill-height class="white">
                <v-row justify="end">
                    <v-col cols="3">
                        <v-menu
                            v-model = "menuFechaIni"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition = "scale-transition"
                            offset-y
                            min-width="auto">
                            
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model = "fechaIni"
                                    label="Fecha de Inicio"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind = "attrs"
                                    v-on = "on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                color="orange darken-4"
                                v-model = "fechaIni"
                                @input = "menuFechaIni = false"
                                @change = "actualizarTablaRegistros"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="3">  
                        <v-menu
                            v-model = "menuFechaFin"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition = "scale-transition"
                            offset-y
                            min-width="auto">
                            
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model = "fechaFin"
                                    label="Fecha de cierre"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind = "attrs"
                                    v-on = "on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                color="orange darken-4"
                                v-model = "fechaFin"
                                @input = "menuFechaFin = false"
                                @change = "actualizarTablaRegistros"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field
                            label = "Buscar elementos por palabra clave"
                            color = "info"
                            v-model = "busquedaTabla"
                            append-icon = "mdi-magnify"
                            placeholder = "Ingresa palabras clave para busqueda en todos los campos"
                            hide-details
                            />
                    </v-col>
                    <v-col cols="1">
                        <v-btn
                            class="ml-4"
                            dark
                            fab
                            color = "green"
                            @click = "actualizarTablaRegistros">
                            <v-icon dark>
                                mdi-update
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table
                            :headers = "hTablaRegistros"
                            :items = "this.$store.state.datosLog"
                            :search = "busquedaTabla"
                            no-results-text = "No hay información para mostrar bajo el rango seleccionado"
                            no-data-text = "Aun no hay información para mostrar"
                            :footer-props="{itemsPerPageText: 'Numero de elementos para mostrar'}"
                            sort-by = "_id"
                            class = "elevation-1">
                            
                            <template v-slot:item.ocupado = "{ item }">
                                <div v-if = "item.ocupado == 0">
                                    <v-icon left color="green darken-2">mdi-map-marker-check</v-icon>
                                    <span class="green--text">Disponible</span>
                                </div>
                                <div v-else>
                                    <v-icon left color="red darken-2">mdi-map-marker-off</v-icon>
                                    <span class="red--text">Ocupado</span>
                                </div>
                            </template>

                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        fechaIni: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        fechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menuFechaIni: false,
        menuFechaFin: false,
        busquedaTabla: "",
        hTablaRegistros: [
            {
                text: 'ID',
                value: '_id',
                sortable: false,
                align: ' d-none'
            },
            {
                text: 'Nombre de la escuela',
                value: 'escuela'
            },
            {
                text: 'Nombre del salon',
                value: 'salon'
            },
            {
                text: 'Fecha y hora de alarma',
                value: 'fecha'
            },
        ]
    }),

    mounted() {
        this.actualizarTablaRegistros();
        this.obtenerUltimosDatos();
    },

    methods: {
        async actualizarTablaRegistros(){
            var filtros = [this.fechaIni, this.fechaFin];
            this.$store.commit("actualizarTablaRegistros", filtros);
        },
      
        obtenerUltimosDatos(){
            setInterval( ()=> {
                this.actualizarTablaRegistros();
            }, 600000);
        },
    }
}
</script>

<style>

</style>