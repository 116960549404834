<template>
  <div class="fill-height mt-5 ml-3">
    <div>
        <span class="title">Configuracion</span>
    </div>
    <div class="mb-5">
        <span class="subtitle-2">Revisa la lista de salones configurados o añade nuevos salones utilizando el boton "añadir nuevo dispositivo"</span>
    </div>
    <div>
        <!--Dialogo de forma de añadir/modificar salones-->
        <forma-salon
            :abrir = dialogFormaSalon
            :nuevo = "true"
            :datos = datosFormaSalon
            :titulo = tituloSalon
            @keydown.esc = "dialogFormaSalon = false"
            @cerrarVentana = "dialogFormaSalon = false"
            @actualizar = "actualizarSalones()"
        />

        <v-container fluid>
            <!--Botones de accion -->
            <v-row justify="end" class="mb-4 mr-4">
                <div>
                    <v-btn
                        :color = color_botones
                        class = "ma-2 white--text"
                        @click = "nuevoSalon()">
                        <v-icon
                            left
                            dark>
                            mdi-home-plus
                        </v-icon>
                        Añadir nuevo dispositivo
                    </v-btn>
                </div>
            </v-row>
            
            <!--Tabla de salones creados-->
            <v-row>
                <v-col>
                    <salones></salones>
                </v-col>
            </v-row>
        </v-container>
    </div>
  </div>
</template>

<script>
import FormaSalon from './extras/FormaSalon.vue';
import Salones from './tablas/Salones.vue';
import axios from 'axios';

  export default {
    
    components: {
        Salones,
        FormaSalon,
    },
    
    data: () => ({
        color_botones: "red darken-1",
      
        tituloSalon: '',
        dialogFormaSalon: false,
        datosFormaSalon: {},
        formaSalonDefault: {
            _id: '',
            salon: '',
            escuela: '',
            idSensor: '',
            contactos: [],
            alarmas: []
        },
    }),

    methods: {
        async nuevoSalon(){
            //Actualizar CB de escuelas
            var url = this.$store.state.api + "escuelas";
            await axios.get(url).then(response => {
                this.$store.commit('setDatosEscuelas', response.data);  
            })
            .catch(error=> {
                this.cuerpoAlerta = "[Configuracion] Error al obtener la información de las escuelas. " + error;
                this.colorAlerta = "error";
                this.titulo = "Lista de escuelas";
                this.alerta = true;
            });
        
            this.datosFormaSalon = this.formaSalonDefault;
            this.tituloSalon = "Añadir nuevo Dispositivo";
            this.dialogFormaSalon = true;
        },

        //Actualiza la lista de salones/dispositivos registrados una vez creado o modificado alguno
        async actualizarSalones(){
            var url = this.$store.state.api + "dispositivos";
            await axios.get(url).then(response => {
                console.log(response.data);
                this.$store.commit('setDatosDispositivos', response.data)  
            })
            .catch(error=> {
                this.cuerpoAlerta = "Error al obtener la información de salones registrados. " + error;
                this.colorAlerta = "error";
                this.titulo = "Lista de areas";
                this.alerta = true;
            });
        },
    }
  };
</script>

<style>

</style>
