import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Tiempos from "../Tiempos";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //api: 'http://localhost:8000/api/alarmas/',
    //api: 'http://10.40.61.52:8000/api/alarmas/',
    api: 'http://162.240.70.249:5000/api/alarmas/',
    vistaActual: "registros",
    datosLog: [],
    datosDispositivos: [],
    areas: [],
    cbEscuelas: [],
    contactosAdded: [],
    alarmasRegistradas: [],
    escuela: ""
  },
  getters: {
    getVistaActual: state => {
      return state.vistaActual;
    },
  },
  mutations: {
    setVistaActual: (state, nuevaVista) => {
      state.vistaActual = nuevaVista;
    },
    setDatosDispositivos: (state, nuevosValores) => {
      state.datosDispositivos = nuevosValores;
    },
    setDatosEscuelas: (state, nuevosValores) => {
      state.cbEscuelas = nuevosValores;
      state.cbEscuelas.push("Otra...");
    },

    actualizarTablaRegistros: (state, filtros) => {
      var fdRegistros = new FormData();
      //console.log("FECHA INI: " + filtros[0]);
      //console.log("FECHA FIN: " + filtros[1]);
      fdRegistros.append("fechaIni", filtros[0]);
      fdRegistros.append("fechaFin", filtros[1]);
      var url = state.api + "registros";
      axios.post(url, fdRegistros).then(response => {
        console.log(response.data);
        if(response.data.length <= 0){
          state.datosLog = [];  
        }else{
          state.datosLog = response.data;
          var opcionesFecha = { year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit", hourCycle: "h12" };
          for(var i=0; i<state.datosLog.length; i++){
              //state.datosLog[i].fecha = Tiempos.getFechaMx(new Date(state.datosLog[i].fecha))
              state.datosLog[i].fecha = new Date(state.datosLog[i].fecha).toLocaleDateString('es-MX', opcionesFecha);
          }
        }
      })
      .catch(error=> {
        console.log("Error actualizando informacion de Lecturas" + error)
      });
    },
  },
  actions: {},
  modules: {},
});