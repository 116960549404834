<template>
  <v-app>
    <barra-superior/>
      <component :is = "this.$store.getters.getVistaActual">
        <v-fade-transition mode="out-in" />
      </component>
  </v-app>
</template>

<script>
import BarraSuperior from './components/extras/BarraSuperior.vue';
import Configuracion from "./components/Configuracion.vue"
import Registros from "./components/Registros.vue"

export default {
  name: "App",

  components: {
    BarraSuperior,
    Configuracion,
    Registros
  },

  data: () => ({
  }),

  mounted(){
    //this.actualizarInfoParking()
    //this.obtenerUltimosDatos()
  },

  methods: {
    /*obtenerUltimosDatos(){
      setInterval( ()=> {
        this.actualizarInfoParking();
      }, 10000);
    },

    actualizarInfoParking(){
      this.$store.commit("actualizarInfoParking");
      this.$store.commit("actualizarInfoArea", this.$store.state.infoArea["nombre"]);
    }*/
  }
};
</script>