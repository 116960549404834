<template>
    <v-dialog
        v-model = "abrir"
        @keydown.esc = "cerrar"
        max-width="800px" 
        scrollable
        persistent>

        <alerta
            :abrir = alerta
            :titulo = tituloAlerta
            :cuerpo = cuerpoAlerta
            :color = colorAlerta
            @cerrarVentana = "alerta = false"
        />
        
        <v-card height="80vh">
            <v-card-title>
                <span class="headline">{{ titulo }}</span>
            </v-card-title>

            <v-card-text>
                <h4 class="mb-3">Llena todos los campos para generar un nuevo dispositivo.</h4>
                <v-form ref="form" v-model = "formaValida">
                <v-tabs
                    color="red"
                    dark
                    fixed-tabs
                    slider-color="yellow"
                    icons-and-text >
                    
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab>
                        General
                        <v-icon>mdi-cog</v-icon>
                    </v-tab>
                    <v-tab>
                        Contactos
                        <v-icon>mdi-account-box</v-icon>
                    </v-tab>
                    <v-tab>
                        Alarmas
                        <v-icon>mdi-alarm</v-icon>
                    </v-tab>
                    
                    <!--PESTAÑA GENERAL-->
                    <v-tab-item>
                        <v-container>
                            <v-row>
                                <v-col cols="10">
                                    <v-select
                                        ref = "escuela"
                                        class = "body-1"
                                        :items = "this.$store.state.cbEscuelas"
                                        v-model = "escuela"
                                        label = "Escuela"
                                        :rules = "[reglas.requerido]"
                                        required
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="10" v-if = "escuela == 'Otra...'">
                                    <v-text-field
                                        ref = "salon"
                                        class = "body-1"
                                        v-model = "claveEscuela"
                                        label = "Clave de la escuela"
                                        :rules = "[reglas.requerido]"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="10" v-if = "escuela == 'Otra...'">
                                    <v-text-field
                                        ref = "salon"
                                        class = "body-1"
                                        v-model = "nombreEscuela"
                                        label = "Nombre de la escuela"
                                        :rules = "[reglas.requerido]"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="10">
                                    <v-text-field
                                        ref = "salon"
                                        class = "body-1"
                                        v-model = "datos['salon']"
                                        label = "Nombre del salon"
                                        :rules = "[reglas.requerido]"
                                        required
                                    />
                                </v-col>
                            </v-row> 
                            <v-row>
                                <v-col cols="10">
                                    <v-text-field
                                        ref = "sensor"
                                        class = "body-1"
                                        v-model = "datos['idSensor']"
                                        label = "ID del sensor (MAC Address)"
                                        :rules = "[reglas.requerido]"
                                        required
                                    />
                                </v-col>
                            </v-row> 
                        </v-container>
                    </v-tab-item>

                    <!--PESTAÑA CONTACTOS -->
                    <v-tab-item>
                        <h4 class="mt-4">{{ instruccionesContactos }} </h4>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="8">
                                    <v-text-field
                                        ref = "correos"
                                        class = "body-1"
                                        v-model = "correos"
                                        label = "Correo(s) electrónico(s)"
                                        v-on:keyup.enter = "addContacto"
                                    />
                                </v-col>
                                <v-col cols="2">
                                    <v-btn
                                        color = "orange"
                                        class = "ma-2 white--text"
                                        @click = "addContacto">
                                        <v-icon
                                            left
                                            dark>
                                            mdi-account-plus
                                        </v-icon>
                                        Añadir
                                    </v-btn>
                                </v-col>
                            </v-row> 
                            <v-row align="center">
                                <v-col cols="8">
                                    <v-list dense>
                                        <v-subheader>Lista de correos electrónicos Añadidos</v-subheader>
                                        <v-list-item-group
                                            v-model = "contactoAddedSeleccionado"
                                            color="primary">

                                            <v-list-item
                                                v-for = "(contacto, i) in this.$store.state.contactosAdded"
                                                :key="i"
                                            >
                                                <v-list-item-icon>
                                                    <v-icon>mdi-email</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text = "contacto"></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn
                                        color = "orange"
                                        class = "ma-2 white--text"
                                        @click = "quitarContactoDeLista">
                                        <v-icon
                                            left
                                            dark>
                                            mdi-account-remove
                                        </v-icon>
                                        Remover seleccion
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>

                    <!--PESTAÑA ALARMAS -->
                    <v-tab-item>
                        <h3>{{ instruccionesAlarmas }} </h3>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-switch
                                                    color="orange darken-4"
                                                    v-model = "recurrente"
                                                    label = "¿Alarma recurrente?"
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if = "recurrente">
                                            <v-col>
                                                <v-switch
                                                    color="orange darken-4"
                                                    v-model = "indefinida"
                                                    label = "Sin fecha final definida"
                                                ></v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if = "recurrente">
                                            <v-col>
                                                <v-menu
                                                    v-model = "menuFechaIni"
                                                    :close-on-content-click = "false"
                                                    :nudge-right="40"
                                                    transition = "scale-transition"
                                                    offset-y
                                                    min-width="auto">
                                                    
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model = "fechaIni"
                                                            label="Fecha de Inicio"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind = "attrs"
                                                            v-on = "on"
                                                            :disabled = "indefinida"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        color="orange darken-4"
                                                        v-model = "fechaIni"
                                                        :max = "fechaFin"
                                                        @input = "menuFechaIni = false"
                                                        :disabled = "indefinida"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if = "recurrente">
                                            <v-col>
                                                <v-menu
                                                    v-model = "menuFechaFin"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition = "scale-transition"
                                                    offset-y
                                                    min-width="auto">
                                                    
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model = "fechaFin"
                                                            label="Fecha Final"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind = "attrs"
                                                            v-on = "on"
                                                            :disabled = "indefinida"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        color="orange darken-4"
                                                        v-model = "fechaFin"
                                                        :min = "fechaIni"
                                                        @input = "menuFechaFin = false"
                                                        :disabled = "indefinida"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if = "!recurrente">
                                            <v-col>
                                                <v-menu
                                                    v-model = "menuFecha"
                                                    :close-on-content-click = "false"
                                                    :nudge-right="40"
                                                    transition = "scale-transition"
                                                    offset-y
                                                    min-width="auto">
                                                    
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model = "fecha"
                                                            label="Fecha de alarma"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind = "attrs"
                                                            v-on = "on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        color="orange darken-4"
                                                        v-model = "fecha"
                                                        @input = "menuFecha = false"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-menu
                                                    ref="menuHoraIni"
                                                    v-model = "menuHoraIni"
                                                    :close-on-content-click = "false"
                                                    :nudge-right="40"
                                                    :return-value.sync = "horaIni"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px">
                                                    
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model = "horaIni"
                                                            label="Hora de inicio"
                                                            prepend-icon = "mdi-clock-time-four-outline"
                                                            readonly
                                                            v-bind = "attrs"
                                                            v-on = "on"
                                                        ></v-text-field>
                                                    </template>
                                                    
                                                    <v-time-picker
                                                        v-if = "menuHoraIni"
                                                        color="orange darken-4"
                                                        v-model = "horaIni"
                                                        :max = "horaFin"
                                                        full-width
                                                        @click:minute = "$refs.menuHoraIni.save(horaIni)"
                                                    ></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-menu
                                                    ref="menuHoraFin"
                                                    v-model = "menuHoraFin"
                                                    :close-on-content-click = "false"
                                                    :nudge-right="40"
                                                    :return-value.sync = "horaFin"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px">
                                                    
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model = "horaFin"
                                                            label="Hora final"
                                                            prepend-icon = "mdi-clock-time-four-outline"
                                                            readonly
                                                            v-bind = "attrs"
                                                            v-on = "on"
                                                        ></v-text-field>
                                                    </template>
                                                    
                                                    <v-time-picker
                                                        v-if = "menuHoraFin"
                                                        v-model = "horaFin"
                                                        color="orange darken-4"
                                                        :min = "horaIni"
                                                        full-width
                                                        @click:minute = "$refs.menuHoraFin.save(horaFin)"
                                                    ></v-time-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if = "recurrente">
                                            <v-col v-for="(dia, i) in diasDeLaSemana"
                                                :key = "i">
                                                <v-checkbox
                                                    color="orange darken-4"
                                                    v-model = "diasSeleccionados[i]"
                                                    :label = "dia"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-btn
                                                    color = "red"
                                                    class = "ma-2 white--text"
                                                    @click = "addAlarma()">
                                                    <v-icon
                                                        left
                                                        dark>
                                                        mdi-alarm-plus
                                                    </v-icon>
                                                    Añadir alarma
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="6">
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-list dense>
                                                    <v-subheader>Alarmas registradas</v-subheader>
                                                    <v-list-item-group
                                                        v-model = "alarmaSeleccionada"
                                                        color="primary">

                                                        <v-list-item
                                                            v-for = "(alarma, i) in this.$store.state.alarmasRegistradas"
                                                            :key = "i"
                                                        >
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-alarm</v-icon>
                                                            </v-list-item-icon>
                                                            
                                                            <!--VISTA RECURRENTE E INFEFINIDA -->
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{ alarma.horaIni }} - {{ alarma.horaFin }}
                                                                </v-list-item-title>
                                                                
                                                                <v-list-item-subtitle  v-if = "alarma.recurrente">
                                                                    ¿Alarma recurrente? <b>Si</b>
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle  v-if = "!alarma.recurrente">
                                                                    ¿Alarma recurrente? <b>No</b>
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle  v-if = "alarma.recurrente && alarma.indefinida">
                                                                    ¿Alarma indefinida? <b>Si</b>
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle  v-if = "!alarma.recurrente">
                                                                    Fecha de ejecucion: <b>{{ alarma.fecha }}</b>
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle  v-if = "alarma.recurrente && !alarma.indefinida">
                                                                    Del <b>{{ alarma.fechaIni }}</b> al <b>{{ alarma.fechaFin }}</b>
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle v-if = "alarma.recurrente">
                                                                    <v-icon small v-if = "alarma.diasRep[0]">mdi-alpha-d-circle</v-icon>
                                                                    <v-icon small v-else>mdi-alpha-d-circle-outline</v-icon>
                                                                    <v-icon small v-if = "alarma.diasRep[1]">mdi-alpha-l-circle</v-icon>
                                                                    <v-icon small v-else>mdi-alpha-l-circle-outline</v-icon>
                                                                    <v-icon small v-if = "alarma.diasRep[2]">mdi-alpha-m-circle</v-icon>
                                                                    <v-icon small v-else>mdi-alpha-m-circle-outline</v-icon>
                                                                    <v-icon small v-if = "alarma.diasRep[3]">mdi-alpha-m-circle</v-icon>
                                                                    <v-icon small v-else>mdi-alpha-m-circle-outline</v-icon>
                                                                    <v-icon small v-if = "alarma.diasRep[4]">mdi-alpha-j-circle</v-icon>
                                                                    <v-icon small v-else>mdi-alpha-j-circle-outline</v-icon>
                                                                    <v-icon small v-if = "alarma.diasRep[5]">mdi-alpha-v-circle</v-icon>
                                                                    <v-icon small v-else>mdi-alpha-v-circle-outline</v-icon>
                                                                    <v-icon small v-if = "alarma.diasRep[6]">mdi-alpha-s-circle</v-icon>
                                                                    <v-icon small v-else>mdi-alpha-s-circle-outline</v-icon>    
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-btn
                                                    color = "red"
                                                    class = "ma-2 white--text"
                                                    @click = "borrarAlarma()">
                                                    <v-icon
                                                        left
                                                        dark>
                                                        mdi-alarm-off
                                                    </v-icon>
                                                    Borrar Alarma
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-container>    
                    </v-tab-item>
                </v-tabs>
            </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn
                    color="red darken-1"
                    text
                    @click="cerrar">Cancelar
                </v-btn>
                <v-btn
                    color="red darken-1"
                    text
                    :disabled = "activarBoton"
                    @click = "guardar">Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios"
import Alerta from '../extras/Alerta.vue';

export default {
    props: {
        datos: Object,
        abrir: Boolean,
        nuevo: Boolean,
        titulo: String,
    },
    components: {
        Alerta     
    },
    data() {
        return {
            reglas: {
                requerido: (value) => !!value || 'Campo requerido',
            },
            formaValida: false,
            cuerpoAlerta: "",
            colorAlerta: "",
            tituloAlerta: "",
            alerta: false,
            
            instruccionesContactos: "Escribe uno o varios correos electrónicos (separados por comas) y da click en el boton 'añadir' para agregarlos a la lista de notificaciones.",
            correos: "",
            contactoAddedSeleccionado: 0,
            //contactosAdded: [],
            
            cbEscuela: ["Otra..."],
            claveEscuela: "",
            nombreEscuela: "",
            escuela: "",

            instruccionesAlarmas: "Llena los campos correspondientes y presiona 'Añadir' para agregar la informacion a la lista de alarmas que enviarán notificación en caso de detectar movimiento en los tiempos seleccionados",
            recurrente: false,
            fechaIni: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            fechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            fecha: new Date(Date.now()).toISOString().substr(0, 10),
            menuFechaIni: false,
            menuFechaFin: false,
            menuFecha: false,
            menuHoraIni: false,
            menuHoraFin: false,
            indefinida: false,
            horaIni: null,
            horaFin: null,
            diasDeLaSemana: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            diasSeleccionados: [false, false, false, false, false, false, false],
            alarmaSeleccionada: 0,
            //alarmasRegistradas: [],
        }
    },
    
    computed: {
        activarBoton(){
            if(!this.nuevo){
                return false;
            }else{
                //return !this.formaValida || !(this.zonaArea.length > 2);
                return !this.formaValida || !(this.$store.state.alarmasRegistradas.length > 0) || !(this.$store.state.contactosAdded.length > 0);
            }
        },
        
        getEtiqueta(){
            if(recurrente == true){
                return "Si"
            }else{
                return "No"
            }
        }
    },
    
    methods: {
        
        //Añade contacto desde el textfield
        addContacto(){
            let cadena = this.correos.split(",");
            let erCorreo = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            for(let i of cadena){
                let temp = i.replace(/\s/g, '');
                //console.log(temp);
                //console.log(erCorreo.test(temp));
                if(erCorreo.test(temp)){
                    this.$store.state.contactosAdded.push(temp);
                }
            }
            this.correos = "";
        },

        //Quita contacto de Lista de distribucion
        quitarContactoDeLista(){
            if(this.contactoAddedSeleccionado > -1){
                this.$store.state.contactosAdded.splice(this.contactoAddedSeleccionado, 1);
            }
            this.contactoAddedSeleccionado = 0;
        },

        //Añadir alarma
        addAlarma(){
            var opcionesAlarma = {}
            if(this.recurrente){
                if(this.indefinida){
                    opcionesAlarma = {
                        recurrente: true,
                        indefinida: true,
                        horaIni: this.horaIni,
                        horaFin: this.horaFin,
                        diasRep: this.diasSeleccionados
                    } 
                }else{
                    opcionesAlarma = {
                        recurrente: true,
                        indefinida: false,
                        fechaIni: this.fechaIni,
                        fechaFin: this.fechaFin,
                        horaIni: this.horaIni,
                        horaFin: this.horaFin,
                        diasRep: this.diasSeleccionados
                    }
                }
            }else{
                opcionesAlarma = {
                    recurrente: false,
                    fecha: this.fecha,
                    horaIni: this.horaIni,
                    horaFin: this.horaFin,
                }
            }
            this.$store.state.alarmasRegistradas.push(opcionesAlarma);

            this.recurrente = false;
            this.indefinida = false;
            this.horaIni = null;
            this.horaFin = null;
            this.fechaIni = new Date(Date.now()).toISOString().substr(0, 10);
            this.fechaFin = new Date(Date.now()).toISOString().substr(0, 10);
            this.fecha = new Date(Date.now()).toISOString().substr(0, 10);
            this.diasSeleccionados = [false, false, false, false, false, false, false];
        },

        borrarAlarma(){
            //console.log("Entre a borrar alarma...");
            //console.log("Alarma seleccionada: " + this.alarmaSeleccionada);
            if(this.alarmaSeleccionada > -1){
                //console.log("indice valido, borrando...");
                this.$store.state.alarmasRegistradas.splice(this.alarmaSeleccionada, 1);
            }
            this.alarmaSeleccionada = 0;
        },

        async guardar() {
            let url = "";
            
            if(this.escuela == 'Otra...'){
                this.datos['escuela'] = this.claveEscuela + " - " + this.nombreEscuela;
            }else{
                this.datos['escuela'] = this.escuela;
            }
            let temp = [];
            for(let i=0; i<this.$store.state.contactosAdded.length; i++){
                temp.push({
                    correo: this.$store.state.contactosAdded[i]
                })
            }
            this.datos['contactos'] = temp;
            this.datos['alarmas'] = this.$store.state.alarmasRegistradas;

            if (!this.nuevo) {
                url = this.$store.state.api + "dispositivos/modificar";

                await axios.post(url, this.datos).then(response => {
                    this.cuerpoAlerta = "Se guardaron los cambios realizados en el dispositivo";
                    this.tituloAlerta = "Modificar dispositivo";
                    console.log(response.status);
                    this.colorAlerta = "success";
                    this.alerta = true;
                    this.cerrar();
                })
                .catch(error => {
                    this.cuerpoAlerta = "Error al modificar el dispositivo. " + error
                    this.colorAlerta = "error";
                    this.tituloAlerta = "Modificar dispositivo";
                    this.alerta = true;
                    this.cerrar();
                });
            }else{
                url = this.$store.state.api + "dispositivos/guardar";

                await axios.put(url, this.datos).then(response => {
                    this.cuerpoAlerta = "Se guardó el dispositivo satisfactoriamente";
                    this.tituloAlerta = "Nuevo dispositivo";
                    console.log(response.status);
                    this.colorAlerta = "success";
                    this.alerta = true;
                    this.cerrar();
                })
                .catch(error => {
                    this.cuerpoAlerta = "Error al guardar el nuevo dispositivo. " + error
                    this.colorAlerta = "error";
                    this.tituloAlerta = "Nuevo dispositivo";
                    this.alerta = true;
                    this.cerrar();
                });
            }
        },
        
        cerrar() {
            //this.$refs.form.reset();
            this.recurrente = false;
            this.indefinida = false;
            this.horaIni = null;
            this.horaFin = null;
            this.fechaIni = new Date(Date.now()).toISOString().substr(0, 10);
            this.fechaFin = new Date(Date.now()).toISOString().substr(0, 10);
            this.fecha = new Date(Date.now()).toISOString().substr(0, 10);
            this.diasSeleccionados = [false, false, false, false, false, false, false];
            this.$store.state.alarmasRegistradas = [];
            this.$store.state.contactosAdded = [];

            this.$emit('cerrarVentana');
            this.$emit('actualizar');
        },
    },
}
</script>