<template>
    <v-dialog
        v-model = "abrir"
        @keydown.esc = "cerrar"
        max-width="300px" 
        persistent>
        
        <v-card>
            <v-card-title v-if = "color == 'error'" class="red lighten-1 white--text">
                <v-icon dark left>mdi-alert-circle</v-icon>
                <span class="headline font-weight-bold">{{ titulo }}</span>
            </v-card-title>
            <v-card-title v-else-if = "color == 'info'" class="blue lighten-1 white--text">
                <v-icon dark left>mdi-alert</v-icon>
                <span class="headline font-weight-bold">{{ titulo }}</span>
            </v-card-title>
            <v-card-title v-else-if = "color == 'success'" class="green lighten-1 white--text">
                <v-icon dark left>mdi-check-circle</v-icon>
                <span class="headline font-weight-bold">{{ titulo }}</span>
            </v-card-title>
            <v-card-title v-else-if = "color == 'warning'" class="orange lighten-1 white--text">
                <v-icon dark left>mdi-alert-circle</v-icon>
                <span class="headline font-weight-bold">{{ titulo }}</span>
            </v-card-title>
            <v-card-title v-else class="amber lighten-1">
                <v-icon dark left>mdi-help-circle</v-icon>
                <span class="headline font-weight-bold">{{ titulo }}</span>
            </v-card-title>

            <v-card-text class="mt-4">
                {{ cuerpo }}
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn
                color="blue darken-1"
                text
                @click="cerrar">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: {
        cuerpo: String,
        abrir: Boolean,
        color: String,
        titulo: String,
    },
    
    methods: {
        cerrar() {
            this.$emit('cerrarVentana');
        },
    },
}
</script>