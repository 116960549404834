<template>
    <v-app-bar color="red darken-4" dark>
        <div class="d-flex align-center">
            <span class="title">Alarmas</span>
        </div>

        <v-spacer></v-spacer>
      
        <!--Opciones en la barra superior-->
        <v-btn
            tile
            plain
            @click = "cambiarVista('registros')">
            <v-icon left>
            mdi-table-clock
            </v-icon>
            Registros
        </v-btn>
        <v-btn
            tile
            plain
            @click = "cambiarVista('configuracion')">
            <v-icon left>
            mdi-cog-outline
            </v-icon>
            Configuración
        </v-btn>
    </v-app-bar>
</template>

<script>
export default {
    data: () => ({
  
    }),
    methods: {
        cambiarVista(vista){
            this.$store.commit('setVistaActual', vista)
        }
    }
}
</script>

<style>

</style>