<template>
    <v-container fluid fill-height>
        
        <!--Dialogo de forma para modificar salones-->
        <forma-salon
            :abrir = dialogFormaSalon
            :nuevo = "false"
            :datos = datosFormaSalon
            :titulo = tituloSalon
            @keydown.esc = "dialogFormaSalon = false"
            @cerrarVentana = "dialogFormaSalon = false"
            @actualizar = "actualizarSalones()"
        />

        <!--Dialogo de Borrar Salones -->
        <v-dialog
            v-model = "preguntaBorrar"
            @keydown.esc = "cerrar"
            max-width="300px" 
            persistent>
            
            <v-card>
                <v-card-title class="red darken-2 white--text">
                    <v-icon dark left>mdi-help-circle</v-icon>
                    <span class="headline font-weight-bold">Borrar salón</span>
                </v-card-title>

                <v-card-text class="mt-4">
                    {{ cuerpoPregunta }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        color="red darken-2"
                        text
                        @click = "borrarSalon">Si
                    </v-btn>
                    <v-btn
                        color="red darken-2"
                        text
                        @click = "preguntaBorrar=false">No
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!--Alertas varias configurable-->
        <alerta
            :abrir = alerta
            :titulo = tituloAlerta
            :cuerpo = cuerpoAlerta
            :color = colorAlerta
            @cerrarVentana = "alerta = false"
        />

        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers = "hSalones"
                            :items = "this.$store.state.datosDispositivos"
                            :search = "busquedaSalones"
                            no-results-text = "No hay información para mostrar"
                            no-data-text = "Aun no hay información para mostrar"
                            :footer-props="{itemsPerPageText: 'Numero de elementos para mostrar'}"
                            sort-by = "_id"
                            class="elevation-1">

                            <template v-slot:item.actions = "{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            color="orange"
                                            class="mr-2"
                                            v-bind = "attrs"
                                            v-on = "on"
                                            @click = "editarSalon(item)">
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span>Editar configuracion del salon</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            small
                                            color="red"
                                            class="mr-2"
                                            v-bind = "attrs"
                                            v-on = "on"
                                            @click = "preguntaBorrarSalon(item)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>Eliminar salón</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import Alerta from '../extras/Alerta.vue';
import FormaSalon from '../extras/FormaSalon.vue';

export default {
    components: { 
      Alerta,
      FormaSalon 
    },

    mounted(){
        this.actualizarSalones();
    },

    data: () => ({
        busquedaSalones: "",
        hSalones: [
            {
                text: "ID",
                value: "_id",
                sortable: false,
                align: ' d-none'
            },
            {
                text: 'Escuela',
                value: 'escuela',
            },
            {
                text: 'Salon',
                value: 'salon',
            },
            {
                text: 'ID de dispositivo',
                value: 'idSensor',
            },
            {
                text: 'Contactos',
                value: 'contactos',
                sortable: false,
                align: ' d-none'
            },
            {
                text: 'Alarmas',
                value: 'alarmas',
                sortable: false,
                align: ' d-none'
            },
            {
                text: 'Acciones',
                value: 'actions',
                sortable: false,
            },
        ],
        
        mapaInicial: true,
        preguntaBorrar: false,
        cuerpoPregunta: '',

        alerta: false,
        tituloAlerta: "",
        cuerpoAlerta: "",
        colorAlerta: "",

        tituloSalon: '',
        dialogFormaSalon: false,
        datosFormaSalon: {},

        alarmasReg: [],
        contactosReg: []
    }),

    methods:{
        //Actualiza la tabla de salones
        async actualizarSalones(){
            var url = this.$store.state.api + "dispositivos";
            await axios.get(url).then(response => {
                console.log(response.data);
                this.$store.commit('setDatosDispositivos', response.data)  
            })
            .catch(error=> {
                this.cuerpoAlerta = "Error al obtener la información de salones registrados. " + error;
                this.colorAlerta = "error";
                this.titulo = "Lista de areas";
                this.alerta = true;
            });
        },

        //editar salon seleccionado
        async editarSalon(item){ 
            //Actualizar CB de escuelas
            let url = this.$store.state.api + "escuelas";
            await axios.get(url).then(response => {
                this.$store.commit('setDatosEscuelas', response.data);  
            })
            .catch(error=> {
                this.cuerpoAlerta = "[Configuracion] Error al obtener la información de las escuelas. " + error;
                this.colorAlerta = "error";
                this.titulo = "Lista de escuelas";
                this.alerta = true;
            });

            this.datosFormaSalon = Object.assign({}, item);
            console.log(this.datosFormaSalon);
            
            //Añadir contactos en el formato de la forma
            for (let contacto of this.datosFormaSalon["contactos"]){
                this.$store.state.contactosAdded.push(contacto.correo);
            }

            //añadir alarmas registradas
            for (let alarma of this.datosFormaSalon["alarmas"]){
                this.$store.state.alarmasRegistradas.push(alarma);
            }

            this.tituloSalon = "Editando dispositivo " + this.datosFormaSalon["idSensor"];
            this.dialogFormaSalon = true;
        },

        preguntaBorrarSalon(item){
            this.datosFormaSalon = Object.assign({}, item);
            this.cuerpoPregunta = `¿Esta seguro de eliminar el salon "${this.datosFormaSalon["salon"]}"?`
            this.preguntaBorrar = true;
        },

        async borrarSalon(){
            this.preguntaBorrar = false;
            console.log("voy a borrar: " + this.datosFormaSalon["_id"]);
            var url = this.$store.state.api + "dispositivos/borrar";
            var idBorrar = {
                _id: this.datosFormaSalon["_id"] 
            }

            console.log(idBorrar);
        
            await axios.post(url, idBorrar).then(response => {
                //console.log(f.datos);
                this.cuerpoAlerta = "La area seleccionada se borró satisfactoriamente";
                this.tituloAlerta = "Borrar area";
                
                console.log(response.status);
                this.colorAlerta = "success";
                this.alerta = true;
                this.actualizarSalones();
            })
            .catch(error => {
                this.cuerpoAlerta = "Error al borrar la area. " + error
                this.colorAlerta = "error";
                this.tituloAlerta = "Borrar area";
                this.alerta = true;
                //this.cerrar();
            });
        }
    }
}
</script>